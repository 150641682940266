import request from '@/utils/request'
// import myrequest from '@/utils/request' 
export function AdminInfo(data) {
  return request({
    method: 'get',
    url: '/api/v2/manager/admin/admin_info',
    params: data
  })
}
export function GetIndex(data) {
  return request({
    method: 'get',
    url: '/api/v2/manager/index/data',
    params: data
  })
}
export function Getnum(data) {
  return request({
    method: 'get',
    url: '/api/v2/manager/index/overview',
    params: data
  })
}
export function Consoleinfo(data) {
  return request({
    method: 'post',
    url: '/api/v2/common/consoleinfo',
    data
  })
}

